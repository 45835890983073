.HomeIndex {
  margin: auto;
  padding: 100px 0;
  max-width: 400px;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}

.HomeIndex-Title {
  font-size: 3.25rem;
  font-weight: 400;
  margin-bottom: 32px;
}

.HomeIndex-Subtitle {
  font-size: 1.5rem;
  line-height: 1.25;
  margin-bottom: 32px;
}
