.List h1 {
  text-align: center;
}

.List h1 p {
  margin-bottom: 0;
}

.ListItems,
.ListItems * {
  border-radius: 8px;
}

.ListItems ul {
  list-style-type: none;
  background-color: #5100a8;
  color: white;
  margin: 0;
  padding: 0;
  font-size: 14pt;
}

.List-Buttons {
  padding: 8px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.List-Buttons div {
  padding: 8px;
}

.ListItem {
  align-items: center;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  padding: 8px;
  color: white;
  background-color: #5100a8;
  transition: all 0.4s ease 0s;
}

.ListItem:hover {
  background-color: #9400a8;
}

.ListItem-Content {
  overflow-wrap: anywhere;
}

.ListItem-Content label {
  display: block;
  margin-bottom: 0;
}

.ListItem-Content p {
  margin-bottom: 0;
}

.ListItem div:not(.ListItem-Control) {
  padding: 0 8px !important;
}

.ListItem-Control {
  padding: 0 !important;
}

.ListModal .modal-content {
  background-color: #002888;
}

.ListModal .modal-header {
  border-bottom: none;
}

.ListModal .modal-footer {
  border-top: none;
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: -0.81rem;
  margin-left: -0.3rem;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 27px;
  width: 27px;
  background-color: #efefff;
  border-radius: 4px;
}

.checkbox-container:hover input ~ .checkmark {
  background-color: #cfcfef;
}

.checkbox-container:active input ~ .checkmark {
  background-color: #bfbfdf;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #005fff;
}

.checkbox-container:hover input:checked ~ .checkmark {
  background-color: #004fef;
}

.checkbox-container:active input:checked ~ .checkmark {
  background-color: #003fdf;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 10px;
  top: 4px;
  width: 8px;
  height: 16px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.Draggable-Helper {
  list-style-type: none;
  border-radius: 8px;
}

.Draggable-Helper li,
.Draggable-Helper button {
  border-radius: 8px;
}
