.Header {
  flex: 0 1 auto;
  padding: 16px;
  background-color: #1850c8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Home-Label {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  margin-bottom: 0;
  padding-left: 12px;
  color: #cfdfff;
}

.Home-Label:hover {
  color: #dfefff;
}

.Home-Label:active {
  color: #eff7ff;
}

.Nav-Links {
  list-style: none;
  margin-bottom: 0;
  font-size: 12pt;
}

.Nav-Links li {
  display: inline-block;
  padding: 0px 12px;
}

.Nav-Links li a {
  color: #cfdfff;
  text-decoration: none;
}

.Nav-Links li a:hover {
  color: #dfefff;
}

.Nav-Links li a:active {
  color: #eff7ff;
}
