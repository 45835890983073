.Register h1 {
  text-align: center;
}

.Register p {
  text-align: justify;
}

.Register small {
  color: #975fd0;
}
